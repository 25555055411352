/**
 * --------------------------------------------------------------------------
 * Bootstrap util/IsTeknoloji-factory.js
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 * --------------------------------------------------------------------------
 */

import SelectorEngine from '../dom/selector-engine.js'
import Config from './config.js'
import { DefaultAllowlist, sanitizeHtml } from './sanitizer.js'
import { execute, getElement, isElement } from './index.js'

/**
 * Constants
 */

const NAME = 'IsTeknolojiFactory'

const Default = {
  allowList: DefaultAllowlist,
  content: {}, // { selector : text ,  selector2 : text2 , }
  extraClass: '',
  html: false,
  sanitize: true,
  sanitizeFn: null,
  IsTeknoloji: '<div></div>'
}

const DefaultType = {
  allowList: 'object',
  content: 'object',
  extraClass: '(string|function)',
  html: 'boolean',
  sanitize: 'boolean',
  sanitizeFn: '(null|function)',
  IsTeknoloji: 'string'
}

const DefaultContentType = {
  entry: '(string|element|function|null)',
  selector: '(string|element)'
}

/**
 * Class definition
 */

class IsTeknolojiFactory extends Config {
  constructor(config) {
    super()
    this._config = this._getConfig(config)
  }

  // Getters
  static get Default() {
    return Default
  }

  static get DefaultType() {
    return DefaultType
  }

  static get NAME() {
    return NAME
  }

  // Public
  getContent() {
    return Object.values(this._config.content)
      .map(config => this._resolvePossibleFunction(config))
      .filter(Boolean)
  }

  hasContent() {
    return this.getContent().length > 0
  }

  changeContent(content) {
    this._checkContent(content)
    this._config.content = { ...this._config.content, ...content }
    return this
  }

  toHtml() {
    const IsTeknolojiWrapper = document.createElement('div')
    IsTeknolojiWrapper.innerHTML = this._maybeSanitize(this._config.IsTeknoloji)

    for (const [selector, text] of Object.entries(this._config.content)) {
      this._setContent(IsTeknolojiWrapper, text, selector)
    }

    const IsTeknoloji = IsTeknolojiWrapper.children[0]
    const extraClass = this._resolvePossibleFunction(this._config.extraClass)

    if (extraClass) {
      IsTeknoloji.classList.add(...extraClass.split(' '))
    }

    return IsTeknoloji
  }

  // Private
  _typeCheckConfig(config) {
    super._typeCheckConfig(config)
    this._checkContent(config.content)
  }

  _checkContent(arg) {
    for (const [selector, content] of Object.entries(arg)) {
      super._typeCheckConfig({ selector, entry: content }, DefaultContentType)
    }
  }

  _setContent(IsTeknoloji, content, selector) {
    const IsTeknolojiElement = SelectorEngine.findOne(selector, IsTeknoloji)

    if (!IsTeknolojiElement) {
      return
    }

    content = this._resolvePossibleFunction(content)

    if (!content) {
      IsTeknolojiElement.remove()
      return
    }

    if (isElement(content)) {
      this._putElementInIsTeknoloji(getElement(content), IsTeknolojiElement)
      return
    }

    if (this._config.html) {
      IsTeknolojiElement.innerHTML = this._maybeSanitize(content)
      return
    }

    IsTeknolojiElement.textContent = content
  }

  _maybeSanitize(arg) {
    return this._config.sanitize ? sanitizeHtml(arg, this._config.allowList, this._config.sanitizeFn) : arg
  }

  _resolvePossibleFunction(arg) {
    return execute(arg, [this])
  }

  _putElementInIsTeknoloji(element, IsTeknolojiElement) {
    if (this._config.html) {
      IsTeknolojiElement.innerHTML = ''
      IsTeknolojiElement.append(element)
      return
    }

    IsTeknolojiElement.textContent = element.textContent
  }
}

export default IsTeknolojiFactory
